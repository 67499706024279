<template>
  <div id="salary" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-1">REPORTES DE VACACIONES</v-row>
      <v-row no-gutters justify="start" class="mt-3 blackList">
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="enterprise">Razón Social</label>
          <select
            name="enterprise"
            id="enterprise"
            v-model="enterprise"
            @change="obtenerReportes()"
          >
            <option selected disabled value="0">Selecciona Empresa</option>
            <option
              v-for="enterprise in enterprises"
              :key="enterprise.id"
              v-bind:value="enterprise.id"
            >
              {{ enterprise.razonSocial }}
            </option>
          </select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="area">Área</label>
          <select name="area" id="area" v-model="area" @change="cambioArea()">
            <option selected value="0">Sin especificar</option>
            <option v-for="area in areas" :key="area.id" v-bind:value="area.id">
              {{ area.nombreArea }}
            </option>
          </select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="sucursal">Centro de trabajo</label>
          <select
            name="sucursal"
            id="sucursal"
            v-model="sucursal"
            @change="cambioSucursal()"
          >
            <option selected value="0">Sin especificar</option>
            <option
              v-for="suc in sucursales"
              :key="suc.id"
              v-bind:value="suc.id"
            >
              {{ suc.nombreSucursal }}
            </option>
          </select>
        </v-col>
      </v-row>
      <v-row no-gutters justify="start" class="mt-10 blackList">
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="reporte">Tipo de reporte</label>
          <select
            name="reporte"
            id="reporte"
            v-model="reporte"
            @change="cambio()"
          >
            <option selected disabled value="0">Seleccione</option>
            <option value="1">Días por disfrutar</option>
            <option value="3">Disfrutados en el mes</option>
            <option value="2">Disfrutados en el año</option>
            <option value="4">Solicitados por disfrutar</option>
          </select>
        </v-col>
      </v-row>
      <v-row v-if="porDisfrutar">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card class="mx-12 tablaReportes">
            <table style="width: 100%">
              <thead>
                <tr>
                  <th>No.Empleado</th>
                  <th>Empleado</th>
                  <th>Puesto</th>
                  <th>Área</th>
                  <th>Fecha de Ingreso</th>
                  <th>Días que corresponde</th>
                  <th>Período</th>
                  <th>Vigencia</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(reporte, r) in reporteGenerado"
                  :key="r"
                  class="text-center"
                >
                  <td class="text-center">
                    {{ reporte.empleadoId }}
                  </td>
                  <td class="text-center">
                    {{ reporte.nombreEmpleado }}
                  </td>
                  <td class="text-center">
                    {{ reporte.puesto }}
                  </td>
                  <td class="text-center">
                    {{ reporte.area }}
                  </td>
                  <td class="text-center">
                    {{ reporte.fechaIngreso }}
                  </td>
                  <td class="text-center">
                    {{ reporte.diasCorresponde }}
                  </td>
                  <td class="text-center">{{ reporte.periodo }}</td>
                  <td class="text-center">
                    {{ reporte.vigencia }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="enAnio">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card class="mx-12 tablaReportes">
            <table style="width: 100%">
              <thead>
                <tr>
                  <th>No.Empleado</th>
                  <th>Empleado</th>
                  <th>Puesto</th>
                  <th>Área</th>
                  <th>Días que corresponden</th>
                  <th>Días disfrutados</th>
                  <th>Días por disfrutar</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(reporte, r) in reporteGenerado"
                  :key="r"
                  class="text-center"
                >
                  <td class="text-center">
                    {{ reporte.empleadoId }}
                  </td>
                  <td class="text-center">
                    {{ reporte.nombreEmpleado }}
                  </td>
                  <td class="text-center">
                    {{ reporte.puesto }}
                  </td>
                  <td class="text-center">
                    {{ reporte.area }}
                  </td>
                  <td class="text-center align-center">
                    {{ reporte.diasCorresponde }}
                  </td>
                  <td class="text-center">
                    {{ reporte.diasDisfrutados }}
                  </td>
                  <td class="text-center">
                    {{ reporte.diasPorDisfrutar }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="enMes">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card class="mx-12 tablaReportes">
            <table style="width: 100%">
              <thead>
                <tr>
                  <th>No.Empleado</th>
                  <th>Empleado</th>
                  <th>Puesto</th>
                  <th>Área</th>
                  <th>Fecha de inicio</th>
                  <th>Fecha fin</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(reporte, r) in reporteGenerado"
                  :key="r"
                  class="text-center"
                >
                  <td class="text-center">
                    {{ reporte.empleadoId }}
                  </td>
                  <td class="text-center">
                    {{ reporte.nombreEmpleado }}
                  </td>
                  <td class="text-center">
                    {{ reporte.puesto }}
                  </td>
                  <td class="text-center">
                    {{ reporte.area }}
                  </td>
                  <td class="text-center">
                    {{ reporte.fechaInicio }}
                  </td>
                  <td class="text-center">
                    {{ reporte.fechaFin }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="solicitados">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card class="mx-12 tablaReportes">
            <table style="width: 100%">
              <thead>
                <tr>
                  <th>No.Empleado</th>
                  <th>Empleado</th>
                  <th>Puesto</th>
                  <th>Área</th>
                  <th>Fecha de inicio</th>
                  <th>Fecha fin</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(reporte, r) in reporteGenerado"
                  :key="r"
                  class="text-center"
                >
                  <td class="text-center">
                    {{ reporte.empleadoId }}
                  </td>
                  <td class="text-center">
                    {{ reporte.nombreEmpleado }}
                  </td>
                  <td class="text-center">
                    {{ reporte.puesto }}
                  </td>
                  <td class="text-center">
                    {{ reporte.area }}
                  </td>
                  <td class="text-center">
                    {{ reporte.fechaInicio }}
                  </td>
                  <td class="text-center">
                    {{ reporte.fechaFin }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="sinDatos" justify="center">
        <v-col sm="5" md="5" lg="5" xl="5" align-self="center">
          <v-card class="ml-2 tablaReportes letraTabla" width="500">
            <table width="100%">
              <thead>
                Riesgo General
              </thead>
              <tbody>
                <tr>
                  Sin datos
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-col>
      </v-row>
      <!--CUANDO ES NUEVA CONSULTA-->
      <v-row
        no-gutters
        justify="center"
        class="mt-3"
        v-if="comienzo == false && sinDatos == false"
      >
        <v-col cols="12" lg="3" md="3" sm="3" xs="3">
          <button class="botonAmarillo" @click="validacion()">Consultar</button>
        </v-col>
      </v-row>
      <!--CUANDO YA SE HA REALIZADO ALGUNA CONSULTA-->
      <v-row
        no-gutters
        justify="center"
        class="mt-3"
        v-if="comienzo == true || sinDatos == true"
      >
        <v-col cols="12" lg="3" md="3" sm="3" xs="3">
          <button class="botonAmarillo" @click="validacion()">
            Nueva consulta
          </button>
        </v-col>
      </v-row>
    </v-container>
    <!--Error-->
    <v-dialog v-model="alert" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ titulo }}</span>
        </v-card-title>
        <v-card-text class="mt-4">
          <h3 class="center">{{ respuesta }}</h3>
          <ul v-if="errors">
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="alert = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      titulo: "",
      expiration: false,
      respuesta: "",
      reporte: 0,
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      areas: [],
      area: 0,
      sucursales: [],
      sucursal: 0,
      alert: false,
      errors: [],
      show: false,
      porDisfrutar: false,
      enAnio: false,
      enMes: false,
      solicitados: false,
      vencidos: false,
      siguienteMes: false,
      comienzo: false,
      sinDatos: false,
      reporteGenerado: [
        {
          idEmpleado: 1,
          nombre: "X Z L",
          puesto: "Gerente",
          area: "RH",
          fechaIngreso: "20-12-20",
          fechas: [
            { fecha: "15-01-19" },
            { fecha: "16-01-20" },
            { fecha: "17-01-20" },
          ],
          periodo: "verano",
          vigencia: "Ok",
        },
        {
          idEmpleado: 2,
          nombre: "X Z L",
          puesto: "Supervisor",
          area: "RH",
          fechaIngreso: "20-12-20",
          fechas: [
            { fecha: "19-01-19" },
            { fecha: "11-01-20" },
            { fecha: "10-01-20" },
          ],
          periodo: "verano",
          vigencia: "Ok",
        },
      ],
      error: false,
    };
  },
  methods: {
    cambio() {
      this.porDisfrutar = false;
      this.enAnio = false;
      this.enMes = false;
      this.solicitados = false;
    },
    validacion() {
      this.errors = [];
      if (this.enterprise == 0) {
        this.titulo = "Reporte de vacaciones";
        this.respuesta = "Es necesario corregir el siguiente error: ";
        this.errors.push("Se debe de elegir la razón social.");
        this.alert = true;
      }
      if (this.enterprise != 0 && this.reporte == 0) {
        this.titulo = "Error al generar el reporte";
        this.respuesta = "Es necesario corregir el siguiente error: ";
        this.errors.push("Se debe elegir el tipo de reporte");
        this.alert = true;
      }
      if (this.reporte == 1) {
        //días por disfrutar
        this.enAnio = false;
        this.enMes = false;
        this.solicitados = false;
        this.porDisfrutar = true;
        this.peticion();
      }
      if (this.reporte == 3) {
        //disfrutados en el mes
        this.enAnio = false;
        this.solicitados = false;
        this.porDisfrutar = false;
        this.enMes = true;
        this.peticion();
      }
      if (this.reporte == 2) {
        //disfrutados en el año
        this.enMes = false;
        this.solicitados = false;
        this.porDisfrutar = false;
        this.enAnio = true;
        this.peticion();
      }
      if (this.reporte == 4) {
        //solicitados
        this.enMes = false;
        this.porDisfrutar = false;
        this.enAnio = false;
        this.solicitados = true;
        this.peticion();
      }
    },
    /*Realiza la solicitud del reporte acorde al tipo de reporte que se desea obtener */
    peticion() {
      //this.alert = true;
      console.log("empresa", this.enterprise);
      console.log("area", this.area);
      console.log("sucursal", this.sucursal);
      console.log("reporte", this.reporte);
      this.comienzo = true;
      this.show = true;
      var queryString =
        "?empresaId=" +
        this.enterprise +
        "&areaId=" +
        this.area +
        "&sucursalId=" +
        this.sucursal;
      axios
        .get(Server + "/vacaciones/reporte/" + this.reporte + queryString, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.reporteGenerado = response.data;
          this.show = false;
          this.comienzo = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.comienzo = true;
          }
        });
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.comienzo = true;
          }
        });
    },
    obtenerReportes() {
      this.reporte = 0;
      this.area = 0;
      this.sucursal = 0;
      this.reporte = 0;
      this.sinDatos = false;
      this.comienzo = false;
      this.areas = [];
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
                nombreCorto: value.nombreAreaCorto,
                empresa: value.empresaId,
                nombreEmpresa: value.empresa,
                nivelJerarquico: value.nivelJerarquico,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.sucursales = [];
      this.show = true;
      axios
        .get(Server + "/sucursales/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.sucursales = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      var x = (document.getElementById("reporte").disabled = false);
      var y = (document.getElementById("area").disabled = false);
      var z = (document.getElementById("sucursal").disabled = false);
    },
    /*Devuelve solo los dominios que pertenezcan a la categoria*/
    filtrar(dominio, categoria) {
      if (dominio.categoriaId == categoria.id) {
        return dominio.nombreDominio;
      }
    },
    /*Devuelve solo las dimensiones que pertenezcan a la categoria*/
    filtrarDimensiones(dimension, categoria) {
      if (categoria.id == dimension.dominio.categoria.id) {
        return dimension.nombreDimension;
      }
    },
    cambioArea() {
      this.sucursal = 0;
      this.reporte = 0;
      this.solicitados = false;
      this.enAnio = false;
      this.enMes = false;
      this.porDisfrutar = false;
    },
    cambioSucursal() {
      this.reporte = 0;
      this.solicitados = false;
      this.enAnio = false;
      this.enMes = false;
      this.porDisfrutar = false;
    },
    limpiar() {
      this.area = 0;
      this.sucursal = 0;
      this.reporte = 0;
      this.areas = [];
      this.sucursales = [];
      this.solicitados = false;
      this.porDisfrutar = false;
      this.enAnio = false;
      this.enMes = false;
      //this.reporteGenerado=[];
      var x = (document.getElementById("reporte").disabled = false);
      var y = (document.getElementById("area").disabled = false);
      var z = (document.getElementById("sucursal").disabled = false);
      this.validacion();
    },
  },
  created() {
    this.listar();
    this.obtenerReportes();
  },
};
</script>